<template>
  <ModalPortal>
    <ModalOverlay @on-close="emit('onClose')">
      <slot></slot>
    </ModalOverlay>
  </ModalPortal>
</template>

<script setup lang="ts">
const emit = defineEmits<{ onClose: [] }>();
</script>
